
#about{
    width:100%;
    min-height:100vh;

    display: flex;
    font-family: main-font;
    background-image: url(../../static/images/about_bg.png);

    

    & #parralax_text{
        position:absolute;
       
        width:60%;
        height:100%;
        padding:10%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        & h2, h3{
            display: flex;
            width: 100%;
            justify-content: start;
            
            color:var(--almost-white);
        }
        & h2{
            font-size: 2.5rem;
        }
        & h3{
            padding-top:1rem;
            font-size: 1.5rem;

        }

        & .description{
            font-size: 1.2rem;
            padding:1rem;
            color:#a4a3a3;
            & a{
                color:inherit;
            }

        }

        & .list{
            width:100%;
            max-height: 350px;
            padding:1rem 5%;
            display: flex;
            flex-wrap: wrap;
            gap:1rem;
            justify-content: center;
            user-select: none;
            

           
            & .tech_item{
                color:white;
                padding:.5rem 1rem;
                text-align: center;
                font-size: 1.2rem;
                border-radius: 1rem;
                flex: 0 0 120px;
            }
        }

    }
    
}

@media (max-width: 1250px) {
    .bg-container{
        height:100vh;
    }
    #about{
        flex-direction: column;
        & #parralax_text{
            position:initial !important;
            width:100%;
            height:100%;
            padding:10%;
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            & h2, h3{
                display: flex;
                width: 100%;
                justify-content: start;
                
                color:var(--almost-white);
            }
            & h2{
                font-size: 2.5rem;
            }
            & h3{
                padding-top:1rem;
                font-size: 1.5rem;

            }

            & .description{
                font-size: 1.2rem;
                padding:1rem;
                color:#a4a3a3;
                & a{
                    color:inherit;
                }

            }

            & .list{
                width:100%;
                max-height: 350px;
                padding:1rem 5%;
                display: flex;
                flex-wrap: wrap;
                gap:1rem;
                justify-content: center;
                user-select: none;
                

            
                & .item{
                    color:white;
                    padding:.5rem 1rem;
                    text-align: center;
                    font-size: 1.2rem;
                    border-radius: 1rem;
                    flex: 0 0 120px;
                }
            }

        }
    }
}