

main{
    font-family: 'main-font';
    padding:5%;
    text-align: center;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    color:white;

    & button {
        all: unset;
        box-sizing: border-box;
        margin:1rem;
        user-select: none;
        cursor: pointer;
        padding: .5rem 1rem;
        border-radius: 15px;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        background-color: var(--almost-white);
        color: var(--almost-black);

        &:hover {
            background-color: var(--almost-black);
            border: 3px solid var(--almost-white);
            color: var(--almost-white);

        }
    }
}