.proyect {
    width: 100%;
    color: var(--almost-white);
    font-family: main-font;

    & .proyect_header {
        padding: .5rem;
        display: flex;

        & button {
            all: unset;
            user-select: none;
            cursor: pointer;
            padding: .5rem 1rem;
            border-radius: 15px;
            font-size: 1.5rem;
            display: flex;
            align-items: center;


            &:hover {
                background-color: var(--almost-white);
                color: var(--almost-black);
            }
        }
    }

    & .info {
        padding: 5% 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        & .title {
            display: flex;
            width: 100%;
            gap: 1rem;
            justify-content: center;
            align-items: center;

            & h1 {
                font-size: 50px;
            }

            & .line {
                display: flex;
                width: 5px;
                min-height: 30px;
                background-color: var(--almost-white);
            }

        }

        & .subtitle {
            display: flex;
            width: 100%;
            text-align: center;
            padding: 1rem 15%;


            & .tech_list {
                display: flex;
                gap: .5rem;
                padding: 1rem;
                justify-content: center;
                flex-wrap: wrap;

                & .tech {
                    padding: .5rem 1rem;
                    border-radius: 15px;
                }
            }
        }

        & button {
            all: unset;
            margin: 1rem;
            padding: 1rem;
            

            background-color: var(--almost-white);
            font-family: main-font;
            color: var(--almost-black);
            font-size: 2rem;
            border-radius: 15px;
            cursor: pointer;
            user-select: none;

            &:hover {
                background-color: var(--almost-black);
                outline: solid 3px var(--almost-white);
                color: var(--almost-white)
            }
        }

    }

    & .image_container {
        display: flex;
        justify-content: center;
        padding: 1rem 5%;

        & img {
            display: flex;
            width: 100%;
            max-width: 1250px;
            height: 100%;
        }
    }
}
button{
    box-sizing: border-box !important;
}
.subtitle{
    flex-direction: column;
}


@media (max-width: 600px) {
    .proyect {
        & .info {
            & .title {
                & h1 {
                    font-size: 2rem !important;
                }
            }
        }

        & .image_container {
            padding: 0;
        }
    }
}