header {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    padding: 5px 15%;
    font-family: main-font;
    align-items: center;
    transition: .5s;



    & .title {
        color: var(--almost-white);
        font-size: 45px;

    }


    & nav {

        display: flex;
        list-style: none;
        padding: 1rem;
        gap: 1rem;
        color: var(--almost-black);
        z-index: 2;
        font-size: 1.6vw;


        & li {
            cursor: pointer;
            user-select: none;
        }


        &:hover {
            color: #22222279;
            transition: .5s;

        }

        .link {
            color: var(--almost-white);
        }


        & li:hover,
        .link:hover {
            color: var(--almost-black);
            transition: .5s;
            text-decoration-line: underline;
            text-decoration-style: wavy;
            text-decoration-thickness: 2px;
            text-underline-position: under;

        }





    }
}

@media (max-width: 1250px) {
    header {
        width: 100%;
        padding: 1rem;
        flex-direction: column;

        & nav {
            justify-content: center;
            color: var(--almost-white);
            font-size: 1.2rem;
            display: flex;
            width: 100%;

            &:hover {
                color: var(--almost-white) !important;
            }

            .link {
                color: var(--almost-white);
            }

            & li:hover,
            .link:hover {
                color: var(--almost-white) !important;
            }
        }



    }


    #mini_header {
        display: none !important;
    }

}


#mini_header {
    display: flex;
    height: 0;
    transition: .5s;
    position: fixed;
    z-index: 10;
    width: 100%;
    justify-content: center;
    background-color: #0000004e;
    font-family: main-font;
    align-items: center;
    overflow: hidden;

    &.moved {
        height: 40px;
    }


    & .title {
        display: none;

    }

    & nav {
        font-size: 20px;
        color: var(--almost-white);
        display: flex;
        list-style: none;
        gap: 1rem;
        padding: .5rem;

        &:hover {
            color: #d9d9d968;
        }

        & li {
            cursor: pointer;
            user-select: none;
        }

        & li:hover {
            color: var(--almost-white);

        }


    }
}