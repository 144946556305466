#landing {
    width: 100%;
    height: 100vh;
    display: flex;
    font-family: main-font;
    background-image: url(../../static/images/background.svg);

    & #description {
        position: absolute;
        color: var(--almost-black);
        font-size: calc(1.7vw + 1.5vh);
        left: 75vw;
        text-align: center;
        top: 50%;
        white-space: nowrap;
        transform: translate(-50%, -50%);

        & a {
            color: var(--almost-white);
            -webkit-text-stroke: 2px var(--almost-black);
            text-decoration: none;
            cursor: pointer;

            &:hover {
                color: var(--almost-black);
                -webkit-text-stroke: 0;




            }
        }
    }

}




#desk {
    position: absolute;
    top: 0;
    left: 200px;
    z-index: 2;
    height: 40vw;
}

@media (max-width: 1250px) {
    #landing {
        background-image: none;

        & #desk {
            display: none;
        }

        & #description {
            position: absolute;
            color: var(--almost-white);
            font-size: 3rem;
            width: 100%;
            padding: 5%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            & a {
                color: var(--almost-black);
                -webkit-text-stroke: 1px var(--almost-white);
                text-decoration: none;


            }
        }

    }
}