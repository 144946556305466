footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    font-family: main-font;
    color:#d9d9d94d;
    & nav{
        display:flex;
        gap:.5rem;

        & a{
            padding:5px;
            border-radius: 5px;
            &:hover{
                background-color: #d9d9d925;

            }
            & img{
                height:35px;
                width:35px;
            }
        }
    }
    
    & #credits{
        padding:.5rem 0;
    }
}