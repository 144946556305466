
#contact{
    width:100%;
    min-height:100vh;
    font-family: main-font;
    background-color: var(--almost-white);

    & .error{
        color:red;
    }

    & .title{
        display:flex;
        justify-content: flex-start;
        padding:5%;
        align-items: flex-end;
        width:100%;
        height:15%;
        font-size: 45px;
    }

   
    & .form{
        display:flex;
        justify-content: space-between;
        
        width:100%;
        height:75%;

        & .subtitle,form{
            display: flex;
            padding-top:5%;
            width:50%;
            height:100%;
        }

        & .subtitle{
            align-items: flex-start;
            padding:0 7%;
            padding-top:5%;
            flex-direction: column;
            font-size: 3rem;
            & span{
                padding-left: 5%;
                font-size: 1.5rem;

            }
        }

        & form{
            display:flex;
            align-items: center;
            flex-direction: column;
            padding:0 5%;
            padding-top: 5%;


            & .item{

                display:flex;
                flex-direction: column;
                width: 100%;
                gap:.5rem;
                padding:.5rem;
                

                & label{
                    font-size: 1.5rem;
                }

                & input,textarea{
                    all:unset;
                    background-color: #1c1c2171;
                    border: 1px solid #1C1C21;
                    font-size: 1.3rem;
                    padding:.5rem;
                    padding-left: 1rem;
                    border-radius: 1rem;
                    color:var(--almost-black);
                }

            }

            

            & .end{
                padding:1rem;
                display: flex;
                justify-content: space-between;
                width:100%;
                
                & .mail{
                    display:flex;
                    align-items: center;
                    color:#1c1c2171;
                    gap:.5rem;

                }

                & button{
                    all:unset;
                    display: flex;
                    user-select: none;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;
                    background-color: #1C1C21;
                    color:var(--almost-white);
                    padding:1rem 1.5rem;
                    font-size: 1.5rem;
                    border-radius: 1rem;
                    gap:.5rem;
                    
                }

            }


        }
    

    }
}

.item{
    display:flex;
}
.mail{
    display:flex;
    align-items: center;
    gap:.5rem;
}

.end{
    display:flex;
    & button{
        display:flex !important;
        gap:.5rem;
    }
}
.subtitle{
    display:flex ;
}

@media (max-width: 1250px) {
    .item{
        width:100%;
    }
    .form{
        flex-direction: column;

        & .subtitle,form{
            display: flex;
            padding:5%;
            width: 100%!important;;
            height:100%;
        }

        & .subtitle{


            flex-direction: column;
            font-size: 2.5rem;
            & span{
                padding-left: 5%;
                font-size: 1rem;

            }
        }

        & .end{
            display:flex;
            flex-direction: column-reverse;
            gap:.5rem;
     
            
            & .mail{
           

            }

            & button{
          
                font-size: 1rem;
                
            }

        }


    
    }
}