#proyects_ref {
    height: 0;
    width: 100vw;
}

#proyects {
    display: flex;
    flex-direction: column;
    font-family: main-font;
    min-height: 100vh;
    width: 100%;

    & .proyect_list {
        color: var(--almost-white);
        padding: 5%;

        & h2 {
            font-size: 2.5rem;
        }

        & .list {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            padding: 1rem 5%;

            & .card {
                &:hover {
                    & img {
                        transform: scale(1.5);
                    }

                    & .subheding {
                        & .type {
                            height: 0 !important;
                        }

                        & .action {
                            height: 100% !important;
                        }
                    }
                }


            }

            & .card {
                background-color: #1c1c21;
                border-radius: 15px;
                box-shadow: 0 7px 29px 0 #64646f33;
                color: inherit;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                text-decoration: none;
                -webkit-user-select: none;
                user-select: none;
                width: 30%;

                & .image_wrapper {
                    border-radius: 15px;
                    display: inline-block;
                    overflow: hidden;
                    width: 100%;

                    & img {
                        transition: all .2s ease;
                        vertical-align: middle;
                        width: 100%;
                    }
                }

                & .card_info {
                    padding: 1rem 1rem 10%;

                    & .heading {
                        align-items: center;
                        display: flex;
                        gap: .5rem;
                        justify-content: flex-start;
                        padding: .5rem 0;

                        & .title {
                            font-size: 1.7rem;
                        }

                        & .line {
                            background-color: #fff;
                            height: 2rem;
                            width: 4px;
                        }

                    }

                    & .subheding {
                        color: #4d4d4d;
                        font-size: 1.5rem;
                        overflow: hidden;

                        & .type {
                            height: 100%;
                            overflow: hidden;
                        }

                        & .action {
                            align-items: center;
                            display: flex;
                            gap: .5rem;
                            height: 0;
                            overflow: hidden;

                            & .line {
                                background-color: #4d4d4d;
                                height: 3px;
                                width: 3rem;
                            }
                        }
                    }
                }

            }

        }

    }


}

@media (max-width: 1250px) {
    .card {
        width: 100% !important;
    }
}