*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
body,html{
    width:100%;
    height:100%;
    background-color: var(--almost-black);
}
.fp-watermark{
    display: none !important;
}